<template>
  <div class="notice">
    <van-tabs v-model="activeName" class="tab" title-active-color="#027eff" color="#027eff" @click="tabChange">
      <van-tab name = '0' title="基础信息"></van-tab>
      <van-tab name = '1' title="任务结果"></van-tab>
    </van-tabs>
    <div v-if="activeName == '0'" class="visitInfo">
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">基础信息</span>
          </template>
        </van-cell>
        <van-cell title="任务名称" :value="dataForm.name" title-style="font-weight:600"/>
        <van-cell title="任务地址" :value="dataForm.address" title-style="font-weight:600"/>
        <van-cell title="走访居民" :value="dataForm.targetStr" title-style="font-weight:600"/>
        <van-cell title="房屋类别" :value="dataForm.houseId == '' || dataForm.houseId == 0?'无房':'有房'" title-style="font-weight:600"/>
        <van-cell title="走访类别" :value="dataForm.visitTypeStr" title-style="font-weight:600"/>
        <van-cell title="查看户信息" value="点击查看" title-style="font-weight:600" value-class="check" @click="toHouseInfo"/>
        <van-cell title="截至时间" :value="dataForm.endTimeStr" title-style="font-weight:600"/>
        <van-cell title="任务归属" :value="dataForm.scopeType==1?'社区':'网格'" title-style="font-weight:600"/>
      </van-cell-group>
      <van-button type="info" size="large" :loading="loading" round @click="applyTask" class="btn" v-show="dataForm.taskStatus == -1">申领任务</van-button>
    </div>
    <div class="cont" v-if="activeName == '1'">
      <!-- <van-popup v-model="helpShow" position="center" closeable>
        <van-cell>
          <div class="help">请求互助</div>
        </van-cell>
        <van-cell title="选中互助人" :value="'请选择'" is-link></van-cell>
        <van-cell>
          <van-button type="info" size="large" round @click="submit">确认</van-button>
        </van-cell>
      </van-popup> -->
        <van-list v-model="result.loading" :finished="result.finished" finished-text="没有更多了" v-if="dataList.length > 0" offset="10">
          <van-cell v-for="(item, index) in dataList" :key="index">
            <van-cell-group>
              <van-cell>
                <van-row>
                  <van-col :span="20">{{item.userList.toString()}}</van-col>
                  <van-col :span="4">第<span style="color: #216EF2">{{dataList.length - index}}</span>次</van-col>
                </van-row>
                <van-row>
                  <van-col :span="item.taskStatus == 0? 20 : 17">{{item.createTime}}</van-col>
                  <van-col :span="7">
                    <van-button type="info" round size="small" @click="getInfo(item.id, item.visitBody, item.fullLocation)">查看详情</van-button>
                  </van-col>
                </van-row>
              </van-cell>
            </van-cell-group>
          </van-cell>
        </van-list>
        <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
        <van-row class="btn">
          <van-button type="primary" size="large" round @click="goSubmit()">上传结果</van-button>
        </van-row>
      </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
export default {
  components :{
    topBar
  },
  data() {
    return {
      loading: false,
      helpShow: false,
      activeName: '0',
      dataList: [],
      dataForm: {},
      result: {
        loading: false,
        finished: false,
      }
    };
  },
  created() {
    this.dataForm = this.$store.state.visitTaskData
    this.getVisitType()
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    // 查看户信息
    toHouseInfo() {
      this.$store.commit('setHouseId', this.dataForm.houseId)
      this.$router.push('./house-info')
    },
    getInfo (id, visitType, location) {
      let type = ''
      if (visitType == 2) {
        type = '3'
      } else {
        type = location ? '1' : '2'
      }
      this.$router.push({path: '/visit-add', query: {id: id, type: type}})
    },
    tabChange(value) {

    },
    getVisitType () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'visitType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({data})=> {
        if (data.code == 0) {
          data.dicts.map(item => {
            if (item.value == this.dataForm.dictId) {
              this.$set(this.dataForm, 'visitTypeStr', item.label)
            }
          })
        }
      })
    },
    getDataList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/dailytasks/annal`),
        method: 'post',
        params: this.$http.adornParams({
          id: this.dataForm.id,
          type: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataList = data.list
          this.dataList.map((item, index) => {
            // this.$set(this.dataList[index], 'userList', item.users.map(val => {
            //   return val.name
            // }))
            this.$set(this.dataList[index], 'userList', item.createUserName)
          })
          // 加载状态结束
          this.result.finished = true
          this.result.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    applyTask () {
      this.$dialog.confirm({
        message: '确定申领任务？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/dailytasks/claim`),
          method: 'post',
          params: this.$http.adornParams({
            dtrId: this.dataForm.dtrId,
            cycleNum: this.dataForm.cycleNum,
            targetId: this.dataForm.targetId,
            taskStatus: 0
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success({
              message: '申领成功',
              duration: 1500,
              onClose: () => {
                this.$router.go(-1)
              }
            })
          }
        })
      })
    },
    goSubmit () {
      this.$router.push({path: '/visit-add', query: {tasks: true, dictId: this.dataForm.dictId, type: this.dataForm.type, targetId: this.dataForm.targetId, street: this.dataForm.street, houseId: this.dataForm.houseId, targetStr: this.dataForm.targetStr}})
    }
  }
}
</script>

<style lang="scss" scoped>
.visitInfo {
  margin-top: 20px;
  background-color: #ffffff !important;
  ::v-deep .van-cell {
    line-height: 56px !important;
  }
  .btn {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 20px;
  }
}
.custom-title {
  font-weight: 600;
}
.check {
  color: #3F8BFC;
}
.cont {
  .btn {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    .van-col:nth-child(1) {
      margin-right: 55px;
    }
  }
  .van-list {
    .van-row:nth-child(2) .van-col:nth-child(2) {
      .van-button--small {
        margin-left: 25px;
      }
    }
  }
  .van-popup {
    width: 550px;
    height: 525px;
    border-radius: 2%;
    ::v-deep .van-popup__close-icon {
      font-size: 37px;
    }
    ::v-deep .van-popup--center {
      top: 43% !important;
    }
    .van-cell:nth-child(1){
      position: relative;
      height: 263px;
      background-image: url('../../assets/img/help.png');
      background-repeat: no-repeat;
      background-size: 100%;
      .help {
        position: absolute;
        top: 30%;
        left: 35%;
        font-size: 38px;
        color: #fff;
      }
    }
    .van-cell:nth-child(1)::after {
      border-bottom: none;
    }
    .van-cell:nth-child(2)::after {
        border-bottom: none;
    }
    .van-cell:nth-child(3) {
      ::v-deep .van-button {
        position: fixed;
        bottom: 30px;
        left: 0;
      }
      ::v-deep .van-button--large {
        width: 60%;
        margin-left: 120px;
        height: 88px;
      }
    }
  }
}
</style>
